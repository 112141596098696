<template>
    <el-dialog :visible.sync="dialogVisible" title="添加产品类别" width="30%">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px">
            <el-form-item label="类别名称" prop="name">
                <el-input type="text" v-model.trim="ruleForm.name" placeholder="请输入类别名称(2-8个字符)"></el-input>
            </el-form-item>
            <el-form-item label="来源">
                <el-select v-model="ruleForm.source" placeholder="请选择来源">
                    <el-option v-for="(item,index) in source" :label="item.title" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit('ruleForm')" v-loading.fullscreen.lock="fullscreenLoading">确 定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        name: "Dialog",
        data(){
            return{
                dialogVisible:false,    //显示本组件
                fullscreenLoading: false,//loading框
                source:[
                    {id:1,title:'自营'},
                    {id:2,title:'代理'},
                ],
                ruleForm: {
                    name:'',
                    source:1,
                },
                rules: {
                    name: [
                        { required: true, message: '请输入类别名称', trigger: 'blur' },
                        { min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur' }
                    ],
                }
            }
        },
        methods:{
            //打开本组件
            open(){
                this.dialogVisible = true;
            },
            //提交表单
            submit(formName) {
                this.$refs[formName].validate(async valid => {
                    if (valid) {
                        this.fullscreenLoading = true;
                        try{
                            const {data} = await this.$axios({
                                method:'POST',
                                url:'/api/secondtitle/add',
                                data:{
                                    name:this.ruleForm.name,
                                    reference:3,
                                    type:this.ruleForm.source,
                                }
                            });
                            this.fullscreenLoading = false;
                            if(data.code){
                                this.$message({
                                    message: data.msg,
                                    type: 'success',
                                });
                                this.$emit('success');
                                this.dialogVisible = false;
                            }else{
                                this.$message.error(data.msg);
                            }
                        }catch (e) {
                            this.fullscreenLoading = false;
                            this.$message.error('网络错误，请稍后重试');
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        },
        watch:{
            //清空表单
            dialogVisible(val){
                if(!val){
                    this.$refs['ruleForm'].resetFields();
                }
            }
        }
    }
</script>

<style scoped>

</style>