<template>
    <div class="productType">
        <div class="container">
            <div class="handle-box">
                <el-button type="primary" icon="el-icon-circle-plus-outline" class="handle-del mr10" @click="add">添加产品类别</el-button>
            </div>
            <el-table v-loading="loading" border :data="tableData" :header-cell-style="{backgroundColor:'#f5f7fa'}">
                <el-table-column align="center" prop="name" label="菜单名称"></el-table-column>
                <el-table-column align="center" prop="status" label="类型">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.type==1" type="success" effect="dark">自营</el-tag>
                        <el-tag v-else type="info" effect="dark">代理</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" icon="el-icon-edit" type="success" size="small">编辑</el-button>
                        <el-button @click="handleDel(scope.row)" icon="el-icon-edit" type="danger" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <Dialog ref="dialog" @success="getList" />
        <editDialog ref="editDialog" @success="getList" />
    </div>
</template>

<script>
    import Dialog from '@/components/ProductType/dialog'
    import editDialog from '@/components/ProductType/editDialog'
    export default {
        name: "ProductType",
        components:{
            Dialog,
            editDialog,
        },
        data(){
            return{
                tableData:[],    //数据列表
                loading:true,   //loading框
            }
        },
        created(){
            this.getList();
        },
        methods:{
            //获取菜单数据
            async getList(){
                try{
                    const {data} = await this.$axios({
                        method:'POST',
                        url:'/api/secondtitle',
                        data:{id:3},
                    });
                    this.loading = false;
                    if(data.code){
                        this.tableData = data.SecondTitle_ret;
                    }
                }catch (e) {
                    //
                }
            },
            //添加菜单
            add(){
                this.$refs.dialog.open();
            },
            //修改菜单信息
            handleEdit(row){
                this.$refs.editDialog.open(row);
            },
            //删除菜单
            handleDel(row){
                // 二次确认删除
                this.$confirm("确定要删除吗？确定后该类别下面所有产品将清空！", "提示", {type: "warning"})
                    .then(async () => {
                        const {data} = await this.$axios({
                            method:'POST',
                            url:'/api/secondtitle/delete',
                            data:{
                                id:row.id,
                            },
                        });
                        if(data.code){
                            this.$message({
                                message: data.msg,
                                type: 'success',
                            });
                            this.getList();
                        }else{
                            this.$message.error(data.msg);
                        }
                    })
                    .catch(() => {
                        //
                    });
            },
        },
    }
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }
</style>